/**
 * Render an array of text as paragraphs, each with its own icon.
 * Supported props: items
 */
import React from 'react';
import Safe from './Safe';
import cake from '../images/icons/bday-cake.png';
import lungs from '../images/icons/lungs.png';
import nurse from '../images/icons/nurse.png';
import steth from '../images/icons/steth.png';
import walkpath from '../images/icons/walk-path.png';

export default function IconList(props) {
  const icons = [
    <img src={cake} alt="cake" className="w-70" />,
    <img src={steth} alt="steth" className="w-70" />,
    <img src={nurse} alt="nurse" className="w-70" />,
    <img src={walkpath} alt="walkpath" className="w-70" />,
    <img src={lungs} alt="lungs" className="w-70" />
  ];
  const iconCount = icons.length;

  return (
    <div className="dashed-item">
      {props.items.map((item, idx) => (
        <div key={`icon_${idx}`} className="flx">
          <div className="v-center-parent p-15">{icons[idx%iconCount]}</div>
          <Safe className="v-center-parent p-15 fg-1" content={item} />
        </div>
      ))}
    </div>
  );
};
