/* A content section comprised only of a header and one or
 * more paragraphs of text.
 * Supported props: src
*/

import React from 'react';
import Safe from './Safe';
import LinkButtons from './LinkButtons';

export default function BasicSection(props) {
  const cfg = props.src;
  const sectionStyle = `basic-section ${cfg.theme||''}`.trim();
  const bodyStyle = 'col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2';
  const headerStyle = `section-header ${cfg.headerclass || ''}`.trim();
  const pStyle = cfg.theme || 'text-black';

  return (
    <div id={cfg.id} className={sectionStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className={bodyStyle}>
            <h1 className={headerStyle}>{cfg.header || cfg.title}</h1>
            {renderParagraphs(cfg.text, pStyle, cfg.displayAs)}
            {cfg.links && <LinkButtons config={cfg.links} theme="mt-12" />}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling, displayType) {
  if (displayType==='list') {
    return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li> )}</ul>;
  }
  else if (displayType==='faq') { return renderOrderedFaq(parags, styling); }
  else if (displayType==='ufaq') { return renderUnorderedFaq(parags, styling); }
  if (!Array.isArray(parags)) return <p>?</p>;
  return parags.map((item, index) => <Safe key={index} className={styling} type="p" content={item} />);
}

function addclass(name) { return (name ? ` ${name}` : ''); }

function renderUnorderedFaq(parags, styling) {
  return parags.map((item, index) => {
    const [q, ans] = item.split('?');
    return (
      <div key={index}>
        <p className={`faq ${styling}`}>{q+'?'}</p>
        <p className={styling}>{ans}</p>
      </div>
    );
  });
}

function renderOrderedFaq(parags, styling) {
  return (
    <ol>
      {parags.map((item, index) => {
        const [q, ans] = item.split('?');
        return (
          <li key={index}>
            <p className={`faq`}>{q+'?'}</p>
            {faqAnswer(ans, styling)}
          </li>
        );
      })}
    </ol>
  );
}

// Support multiple paragraphs by parsing by delimiter (semi-colon).
function faqAnswer(text, styling) {
  if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <p key={`faqp-${idx}`} className={styling}>{parag}</p>);
  return <p className={styling}>{text}</p>;
}
