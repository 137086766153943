/* Home page */

import React from 'react';
import { withPrefix, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

export default function Home(props) {
  const ctx = props.pageContext;
  // console.log(`Current ctx at ${new Date().toLocaleTimeString()}`, ctx);
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
        <script src={withPrefix('optanonwrapper.js')} />
      </Helmet>
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      sitename
      siteurl
      title
      subtitle
      metaDescription
      homelink
      startstudy
      fixedpanel {
        cta
        btntheme
      }
      banner {
        theme
        btntheme
      }
      navbar {
        theme
      }
      sections {
        id
        linkto
        title
        theme
        text
        footerLabel
        data {
          content
          items
          styling
          list
        }
        header
        headerclass
        links {
          label
          to
        }
      }
      footer {
        links {
          label
          to
        }
        text
        theme
      }
      patientFields {
        firstname
        lastname
        phone
        email
        address
        referredbyDefault
        referredby {
          id
          value
        }
        cta
        ctaDq
      }
      errorpage {
        header
        subheader
        appError
      }
      screener {
        questions {
          q
          proceedIfYes
          theme
          alias
          canSkip
          footer
        }
        questionLabel {
          noun
          prep
          affirm
          deny
        }
        successResult {
          header
          message
          theme
          thankyou {
            locationpicked {
              theme
              header
              text
            }
            nolocation {
              theme
              header
              text
            }
          }
        }
        failResult {
          header
          message
          theme
          thankyou {
            theme
            header
            text
          }
        }
        locations {
          cta
          ctabutton
          header
          noSuitableSite
          addresses {
            name
            street
            city
            state
            zip
            lat
            lng
          }
        }
      }
    }
  }`;
