/* A content section that allows more flexible composition of text.
 * Supported props: src
 * Instead of configuring content in "src.text[]" we use "src.data: [{content,items,styling}]"
*/

import React from 'react';
import Safe from './Safe';
import IconList from './IconList';
import List from './List'

export default function ComposedSection(props) {
  const cfg = props.src;
  const sectionStyle = `basic-section ${cfg.theme||''}`.trim();
  const bodyStyle = 'col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2';
  const headerStyle = `section-header ${cfg.headerclass || ''}`.trim();
  // Optionally add a css class for proper display of header against a transparent background
  const headerBg = `headline-font${addclass(cfg.headerbg || '')}`;
  const pStyle = 'text-black';

  return (
    <div id={cfg.id} className={sectionStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className={bodyStyle}>
            <h1 className={headerStyle}><span className={headerBg}>{cfg.header || cfg.title}</span></h1>
            {renderParagraphs(cfg.data, pStyle)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling) {
  return parags.reduce((output, paragraph, index) => {
    if (paragraph.items) {
      // Render a list
      output.push(<IconList key={`list-${index}`} items={paragraph.items} />);
    } 
    else if (paragraph.list) {
      output.push(<List key={`list-${index}`} items={paragraph.list} />)
    }
    else {
      // Render a paragraph
      const pStyle = (paragraph.styling ? `${styling} ${paragraph.styling}` : styling);
      output.push(<Safe key={index} type="p" className={pStyle} content={paragraph.content} />);
    }
    return output;
    }, []);
}

function addclass(name) { return (name ? ` ${name}` : ''); }
