/**
 * Render an array of text as paragraphs, each with its own icon.
 * Supported props: items
 */
import React from 'react';
import Safe from './Safe';

export default function List(props) {
  return (
    <ul>
      {props.items.map((item, idx) => (
        <Safe key={`list_${idx}`} type="li" className="fg-1" content={item} />
      ))}
    </ul>
  );
};
