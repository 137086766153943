/**
 * Render an array of link objects as a horizontal row of buttons.
 * Supported props: config theme
 */
import React from 'react';
import { Link } from 'gatsby';
import Safe from './Safe';

export default function LinkButtons(props) {
  const styling = 'flx' + (props.theme ? ` ${props.theme}` : '');
  return (
    <div className={styling}>
      {props.config.map((btn, index) => (
        <div key={`btn-${index}`} className="p-8">
          <Link to={btn.to} className="get-started-btn rounded-corner-ne white-on-pary link-button"><Safe content={btn.label} /></Link>
        </div>
      ))}
    </div>
  );
};
