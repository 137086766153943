/**
 * A component that provides a selection of sites.
 */
import React from 'react';
import sortfn from '../util/geotools';

export default function LocationsForm(props) {
  // Display only top 7 closest locations
  const locations = (props.locations ? sortfn(props.patientLoc, props.locations.addresses) : []).filter((loc,idx) => idx<7);
  const ctabutton = props.locations.ctabutton;
  function siteFn(site) { return function(e) { e.preventDefault(); props.setLocation(site); }; }

  return (
    <div className="locations">
      <div className="row">
        <div className="col-sm-6">
          <div className="first">
            {locations.slice(0,4).map((item, index) => {
              return (
                <div key={`addr_${index}`} className="locations-row">
                  <div className="row">
                    {renderSite(item, siteFn(item), ctabutton)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="second">
            {locations.slice(4).map((item, index) => {
              return (
                <div key={`addr_${index}`} className="locations-row">
                  <div className="row">
                    {renderSite(item, siteFn(item), ctabutton)}
                  </div>
                </div>
              );
            })}
            <div className="locations-row no-site mt-12">
              <div className="row">
                <div className="col-sm-9">
                  <div className="loc-title">
                    <p><strong>{props.locations.noSuitableSite}</strong></p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <button className="location-btn nopick" onClick={siteFn(null)}>{ctabutton}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderSite(site, handler, buttonText) {
  return (
    <div className="row">
      <div className="col-sm-7">
        <div className="loc-title">
          <p><strong>{site.name}</strong></p>
        </div>
        <div className="street-block">
          <div className="thoroughfare">{site.street}</div>
        </div>
        <div className="addressfield-container-inline locality-block country-US">
          <span className="locality">{site.city}, </span>
          <span className="state">{site.state} </span>
          <span className="postal-code">{site.zip}</span>
        </div>
      </div>
      <div className="col-sm-2">
        <span className="miles">{site.dist} Mi</span>
      </div>
      <div className="col-sm-3">
        <button className="location-btn locpick" onClick={handler}>{buttonText}</button>
      </div>
    </div>
  );
}
